import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sb-warning-dialog',
  templateUrl: './warning-dialog.component.html',
})
export class WarningDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmText: string;
      cancelText?: string;
      variant?: 'basic' | 'primary' | 'warn' | 'danger' | 'success';
    },
    private dialogRef: MatDialogRef<WarningDialogComponent>
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
