import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

const featureTesterKey = 'feature_tester';
const supporterKey = 'supporter';

@Injectable({
  providedIn: 'root',
})
export class SbAuthService extends AuthService {
  private hasRole(roleKey: string): Observable<boolean> {
    return combineLatest([this.idTokenClaims$, this.isAuthenticated$]).pipe(
      map(([claims, isAuthenticated]) => {
        if (!isAuthenticated) {
          this.loginWithPopup().subscribe({
            next: () => {
              location.reload();
            },
            error: (err) => {
              if (err.error === 'cancelled') {
                this.logout();
              }
            },
          });
        }

        if (!claims) {
          return false;
        }

        const roles: string[] = claims[environment.auth0Namespace + '/roles'] ?? [];
        return roles.includes(roleKey);
      })
    );
  }

  isBetaTester(): Observable<boolean> {
    return this.hasRole(featureTesterKey);
  }

  isSupporter(): Observable<boolean> {
    return this.hasRole(supporterKey);
  }
}
