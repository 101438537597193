<mat-card appearance="outlined" class="!tw-border-none">
  <mat-card-header>
    <mat-card-title>
      {{ data.title }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="!tw-my-2">
    <p>{{ data.message }}</p>
  </mat-card-content>

  <mat-card-footer>
    <mat-card-actions align="end" class="tw-gap-3">
      @if (data.cancelText) {
      <button data-sentry-id="dialog-cancel-btn" mat-button (click)="onCancel()">{{ data.cancelText }}</button>
      }
      <button
        data-sentry-id="dialog-confirm-btn"
        mat-button
        (click)="onConfirm()"
        class="mat-{{ data.variant || 'danger' }}"
      >
        {{ data.confirmText }}
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
